/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


/* MaplibreGL CSS */
@import '~maplibre-gl/dist/maplibre-gl.css';

@import 'ngx-toastr/toastr';

:root {
  --overlie-text-color: #ffffff;
  --overlie-secondary-text-color: #a5a5a5;
  --overlie-tertiary-text-color: #838383;
  --overlie-main-background: #181818;
  --overlie-control-background: #292929;
  --overlie-special-marker-background: #C93131;
  --overlie-gradient: #c37500 0%, #c40039 33%, #b100c1 66%, #16009c 100%;
  --overlie-standard-border: solid 1px white;
  --overlie-progress-color: white;
  --overlie-popover-width: 80%;
  --overlie-flame-color: #fc602c;
  --overlie-text-shadow: 0px 0px 10px #00000080;
  --overlie-icon-shadow: 0px 0px 10px #00000080;
}

* {
  font-family: 'Saira';
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--overlie-control-background);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--overlie-secondary-text-color);
}

.toast-container .ngx-toastr {
  box-shadow: none !important;
  border-radius: 10px !important;
  background-color: var(--overlie-main-background) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 4px !important;
  background-position: 0% 100% !important;
  padding: 12px 15px;
  font-family: 'Saira';
  top: max(12px, env(safe-area-inset-top));
}

.toast-success {
  background-image: linear-gradient(to right, var(--overlie-gradient)) !important;
}

.toast-warning {
  background-image: linear-gradient(to right, #ff5f6d, #ffc371) !important;
}

.toast-error {
  background-image: linear-gradient(to right, #fc2828, #f82cb4) !important;
}

.toast-info {
  background-image: linear-gradient(to right, var(--overlie-gradient)) !important;
}

.toast-close-button {
  top: 0.5em;
  right: 0;
  transition: all 0.2s ease-in-out;
}

.toast-close-button:hover {
  color: #fff !important;
}

.toast-container:hover .ngx-toastr:hover {
  box-shadow: none !important;
}

.animated-star {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 999999;
}

.animated-star-amount {
  position: absolute;
  z-index: 999999;
  font-size: 1em;
  color: #f9db78;
  text-shadow: 0 0 5px #000000;
  font-weight: bold;
}

ion-fab-button {
  --background: var(--overlie-main-background);
  --border-radius: 15px;
  --background-hover: var(--overlie-control-background);
  --background-activated: var(--overlie-control-background);
  z-index: 1000;
  width: 3rem;
  height: 3rem;
}

ion-fab-button[disabled=true] {
  --background: var(--overlie-main-background);  
  --background-hover: var(--overlie-main-background);
  opacity: 1;
}

ion-fab-button[disabled=true] ion-icon {
  opacity: 0.5;
}

ion-fab-button ion-icon {
  font-size: 20px;
}

.fade {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.page {
  display: block;
  padding: 1rem;
  overflow: auto;
}